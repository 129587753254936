"use strict";
/* tslint:disable */
/* eslint-disable */
/**
 * RouteZero API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (Object.prototype.hasOwnProperty.call(b, p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        if (typeof b !== "function" && b !== null)
            throw new TypeError("Class extends value " + String(b) + " is not a constructor or null");
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g = Object.create((typeof Iterator === "function" ? Iterator : Object).prototype);
    return g.next = verb(0), g["throw"] = verb(1), g["return"] = verb(2), typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (g && (g = 0, op[0] && (_ = 0)), _) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.DefaultApi = void 0;
var runtime = require("../runtime");
var index_1 = require("../models/index");
/**
 *
 */
var DefaultApi = /** @class */ (function (_super) {
    __extends(DefaultApi, _super);
    function DefaultApi() {
        return _super !== null && _super.apply(this, arguments) || this;
    }
    /**
     * Find a pair of airports between which a flight could be used as part of a journey.
     */
    DefaultApi.prototype.v1AirportsPostRaw = function (requestParameters, initOverrides) {
        return __awaiter(this, void 0, void 0, function () {
            var queryParameters, headerParameters, response;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        if (requestParameters.airportsRequest === null || requestParameters.airportsRequest === undefined) {
                            throw new runtime.RequiredError('airportsRequest', 'Required parameter requestParameters.airportsRequest was null or undefined when calling v1AirportsPost.');
                        }
                        queryParameters = {};
                        headerParameters = {};
                        headerParameters['Content-Type'] = 'application/json';
                        return [4 /*yield*/, this.request({
                                path: "/v1/airports",
                                method: 'POST',
                                headers: headerParameters,
                                query: queryParameters,
                                body: (0, index_1.AirportsRequestToJSON)(requestParameters.airportsRequest),
                            }, initOverrides)];
                    case 1:
                        response = _a.sent();
                        return [2 /*return*/, new runtime.JSONApiResponse(response, function (jsonValue) { return (0, index_1.AirportsResponseFromJSON)(jsonValue); })];
                }
            });
        });
    };
    /**
     * Find a pair of airports between which a flight could be used as part of a journey.
     */
    DefaultApi.prototype.v1AirportsPost = function (requestParameters, initOverrides) {
        return __awaiter(this, void 0, void 0, function () {
            var response;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0: return [4 /*yield*/, this.v1AirportsPostRaw(requestParameters, initOverrides)];
                    case 1:
                        response = _a.sent();
                        return [4 /*yield*/, response.value()];
                    case 2: return [2 /*return*/, _a.sent()];
                }
            });
        });
    };
    /**
     * Retrive the state of a booking.
     */
    DefaultApi.prototype.v1BookingIdStateGetRaw = function (requestParameters, initOverrides) {
        return __awaiter(this, void 0, void 0, function () {
            var queryParameters, headerParameters, token, tokenString, response;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        if (requestParameters.id === null || requestParameters.id === undefined) {
                            throw new runtime.RequiredError('id', 'Required parameter requestParameters.id was null or undefined when calling v1BookingIdStateGet.');
                        }
                        queryParameters = {};
                        headerParameters = {};
                        if (!(this.configuration && this.configuration.accessToken)) return [3 /*break*/, 2];
                        token = this.configuration.accessToken;
                        return [4 /*yield*/, token("bearerAuth", [])];
                    case 1:
                        tokenString = _a.sent();
                        if (tokenString) {
                            headerParameters["Authorization"] = "Bearer ".concat(tokenString);
                        }
                        _a.label = 2;
                    case 2: return [4 /*yield*/, this.request({
                            path: "/v1/booking/{id}/state".replace("{".concat("id", "}"), encodeURIComponent(String(requestParameters.id))),
                            method: 'GET',
                            headers: headerParameters,
                            query: queryParameters,
                        }, initOverrides)];
                    case 3:
                        response = _a.sent();
                        return [2 /*return*/, new runtime.JSONApiResponse(response, function (jsonValue) { return (0, index_1.GetBookingStateResponseFromJSON)(jsonValue); })];
                }
            });
        });
    };
    /**
     * Retrive the state of a booking.
     */
    DefaultApi.prototype.v1BookingIdStateGet = function (requestParameters, initOverrides) {
        return __awaiter(this, void 0, void 0, function () {
            var response;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0: return [4 /*yield*/, this.v1BookingIdStateGetRaw(requestParameters, initOverrides)];
                    case 1:
                        response = _a.sent();
                        return [4 /*yield*/, response.value()];
                    case 2: return [2 /*return*/, _a.sent()];
                }
            });
        });
    };
    /**
     * Request to pay on-account for a booking. If on-account booking is enabled for the customer, an order will be placed with the 3rd party travel provider and an invoice sent to the customer.
     */
    DefaultApi.prototype.v1BookingOnAccountPaymentPostRaw = function (requestParameters, initOverrides) {
        return __awaiter(this, void 0, void 0, function () {
            var queryParameters, headerParameters, token, tokenString, response;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        if (requestParameters.onAccountPaymentRequest === null || requestParameters.onAccountPaymentRequest === undefined) {
                            throw new runtime.RequiredError('onAccountPaymentRequest', 'Required parameter requestParameters.onAccountPaymentRequest was null or undefined when calling v1BookingOnAccountPaymentPost.');
                        }
                        queryParameters = {};
                        headerParameters = {};
                        headerParameters['Content-Type'] = 'application/json';
                        if (!(this.configuration && this.configuration.accessToken)) return [3 /*break*/, 2];
                        token = this.configuration.accessToken;
                        return [4 /*yield*/, token("bearerAuth", [])];
                    case 1:
                        tokenString = _a.sent();
                        if (tokenString) {
                            headerParameters["Authorization"] = "Bearer ".concat(tokenString);
                        }
                        _a.label = 2;
                    case 2: return [4 /*yield*/, this.request({
                            path: "/v1/booking/on-account-payment",
                            method: 'POST',
                            headers: headerParameters,
                            query: queryParameters,
                            body: (0, index_1.OnAccountPaymentRequestToJSON)(requestParameters.onAccountPaymentRequest),
                        }, initOverrides)];
                    case 3:
                        response = _a.sent();
                        return [2 /*return*/, new runtime.VoidApiResponse(response)];
                }
            });
        });
    };
    /**
     * Request to pay on-account for a booking. If on-account booking is enabled for the customer, an order will be placed with the 3rd party travel provider and an invoice sent to the customer.
     */
    DefaultApi.prototype.v1BookingOnAccountPaymentPost = function (requestParameters, initOverrides) {
        return __awaiter(this, void 0, void 0, function () {
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0: return [4 /*yield*/, this.v1BookingOnAccountPaymentPostRaw(requestParameters, initOverrides)];
                    case 1:
                        _a.sent();
                        return [2 /*return*/];
                }
            });
        });
    };
    /**
     * Confirm booking options including seating preferences, ticket collection type, traveller personal details, etc. Required to move to the checkout screen.
     */
    DefaultApi.prototype.v1BookingOptionsPostRaw = function (requestParameters, initOverrides) {
        return __awaiter(this, void 0, void 0, function () {
            var queryParameters, headerParameters, token, tokenString, response;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        if (requestParameters.confirmBookingOptionsRequest === null || requestParameters.confirmBookingOptionsRequest === undefined) {
                            throw new runtime.RequiredError('confirmBookingOptionsRequest', 'Required parameter requestParameters.confirmBookingOptionsRequest was null or undefined when calling v1BookingOptionsPost.');
                        }
                        queryParameters = {};
                        headerParameters = {};
                        headerParameters['Content-Type'] = 'application/json';
                        if (!(this.configuration && this.configuration.accessToken)) return [3 /*break*/, 2];
                        token = this.configuration.accessToken;
                        return [4 /*yield*/, token("bearerAuth", [])];
                    case 1:
                        tokenString = _a.sent();
                        if (tokenString) {
                            headerParameters["Authorization"] = "Bearer ".concat(tokenString);
                        }
                        _a.label = 2;
                    case 2: return [4 /*yield*/, this.request({
                            path: "/v1/booking/options",
                            method: 'POST',
                            headers: headerParameters,
                            query: queryParameters,
                            body: (0, index_1.ConfirmBookingOptionsRequestToJSON)(requestParameters.confirmBookingOptionsRequest),
                        }, initOverrides)];
                    case 3:
                        response = _a.sent();
                        return [2 /*return*/, new runtime.JSONApiResponse(response, function (jsonValue) { return (0, index_1.ConfirmBookingOptionsResponseFromJSON)(jsonValue); })];
                }
            });
        });
    };
    /**
     * Confirm booking options including seating preferences, ticket collection type, traveller personal details, etc. Required to move to the checkout screen.
     */
    DefaultApi.prototype.v1BookingOptionsPost = function (requestParameters, initOverrides) {
        return __awaiter(this, void 0, void 0, function () {
            var response;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0: return [4 /*yield*/, this.v1BookingOptionsPostRaw(requestParameters, initOverrides)];
                    case 1:
                        response = _a.sent();
                        return [4 /*yield*/, response.value()];
                    case 2: return [2 /*return*/, _a.sent()];
                }
            });
        });
    };
    /**
     * Create a booking using the given booking options obtained using a search endpoint (e.g. /search-outward-train-journeys).
     */
    DefaultApi.prototype.v1BookingPostRaw = function (requestParameters, initOverrides) {
        return __awaiter(this, void 0, void 0, function () {
            var queryParameters, headerParameters, token, tokenString, response;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        if (requestParameters.startBookingRequest === null || requestParameters.startBookingRequest === undefined) {
                            throw new runtime.RequiredError('startBookingRequest', 'Required parameter requestParameters.startBookingRequest was null or undefined when calling v1BookingPost.');
                        }
                        queryParameters = {};
                        headerParameters = {};
                        headerParameters['Content-Type'] = 'application/json';
                        if (!(this.configuration && this.configuration.accessToken)) return [3 /*break*/, 2];
                        token = this.configuration.accessToken;
                        return [4 /*yield*/, token("bearerAuth", [])];
                    case 1:
                        tokenString = _a.sent();
                        if (tokenString) {
                            headerParameters["Authorization"] = "Bearer ".concat(tokenString);
                        }
                        _a.label = 2;
                    case 2: return [4 /*yield*/, this.request({
                            path: "/v1/booking",
                            method: 'POST',
                            headers: headerParameters,
                            query: queryParameters,
                            body: (0, index_1.StartBookingRequestToJSON)(requestParameters.startBookingRequest),
                        }, initOverrides)];
                    case 3:
                        response = _a.sent();
                        return [2 /*return*/, new runtime.JSONApiResponse(response, function (jsonValue) { return (0, index_1.StartBookingResponseFromJSON)(jsonValue); })];
                }
            });
        });
    };
    /**
     * Create a booking using the given booking options obtained using a search endpoint (e.g. /search-outward-train-journeys).
     */
    DefaultApi.prototype.v1BookingPost = function (requestParameters, initOverrides) {
        return __awaiter(this, void 0, void 0, function () {
            var response;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0: return [4 /*yield*/, this.v1BookingPostRaw(requestParameters, initOverrides)];
                    case 1:
                        response = _a.sent();
                        return [4 /*yield*/, response.value()];
                    case 2: return [2 /*return*/, _a.sent()];
                }
            });
        });
    };
    /**
     * Requests to check for stalled orders. If any are detected, stalled-order events will be emitted.
     */
    DefaultApi.prototype.v1CheckStalledOrdersPostRaw = function (initOverrides) {
        return __awaiter(this, void 0, void 0, function () {
            var queryParameters, headerParameters, token, tokenString, response;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        queryParameters = {};
                        headerParameters = {};
                        if (!(this.configuration && this.configuration.accessToken)) return [3 /*break*/, 2];
                        token = this.configuration.accessToken;
                        return [4 /*yield*/, token("bearerAuth", [])];
                    case 1:
                        tokenString = _a.sent();
                        if (tokenString) {
                            headerParameters["Authorization"] = "Bearer ".concat(tokenString);
                        }
                        _a.label = 2;
                    case 2: return [4 /*yield*/, this.request({
                            path: "/v1/check-stalled-orders",
                            method: 'POST',
                            headers: headerParameters,
                            query: queryParameters,
                        }, initOverrides)];
                    case 3:
                        response = _a.sent();
                        return [2 /*return*/, new runtime.VoidApiResponse(response)];
                }
            });
        });
    };
    /**
     * Requests to check for stalled orders. If any are detected, stalled-order events will be emitted.
     */
    DefaultApi.prototype.v1CheckStalledOrdersPost = function (initOverrides) {
        return __awaiter(this, void 0, void 0, function () {
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0: return [4 /*yield*/, this.v1CheckStalledOrdersPostRaw(initOverrides)];
                    case 1:
                        _a.sent();
                        return [2 /*return*/];
                }
            });
        });
    };
    /**
     * Retrieves a list of countries, containing their names and iso codes.
     * Retrieves a list of countries
     */
    DefaultApi.prototype.v1CountriesGetRaw = function (initOverrides) {
        return __awaiter(this, void 0, void 0, function () {
            var queryParameters, headerParameters, token, tokenString, response;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        queryParameters = {};
                        headerParameters = {};
                        if (!(this.configuration && this.configuration.accessToken)) return [3 /*break*/, 2];
                        token = this.configuration.accessToken;
                        return [4 /*yield*/, token("bearerAuth", [])];
                    case 1:
                        tokenString = _a.sent();
                        if (tokenString) {
                            headerParameters["Authorization"] = "Bearer ".concat(tokenString);
                        }
                        _a.label = 2;
                    case 2: return [4 /*yield*/, this.request({
                            path: "/v1/countries",
                            method: 'GET',
                            headers: headerParameters,
                            query: queryParameters,
                        }, initOverrides)];
                    case 3:
                        response = _a.sent();
                        return [2 /*return*/, new runtime.JSONApiResponse(response, function (jsonValue) { return (0, index_1.CountriesResponseFromJSON)(jsonValue); })];
                }
            });
        });
    };
    /**
     * Retrieves a list of countries, containing their names and iso codes.
     * Retrieves a list of countries
     */
    DefaultApi.prototype.v1CountriesGet = function (initOverrides) {
        return __awaiter(this, void 0, void 0, function () {
            var response;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0: return [4 /*yield*/, this.v1CountriesGetRaw(initOverrides)];
                    case 1:
                        response = _a.sent();
                        return [4 /*yield*/, response.value()];
                    case 2: return [2 /*return*/, _a.sent()];
                }
            });
        });
    };
    /**
     * Returns currency ISO code for the country the request was sent from. Location is determined using the IP address.
     */
    DefaultApi.prototype.v1CountryCurrencyGetRaw = function (initOverrides) {
        return __awaiter(this, void 0, void 0, function () {
            var queryParameters, headerParameters, token, tokenString, response;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        queryParameters = {};
                        headerParameters = {};
                        if (!(this.configuration && this.configuration.accessToken)) return [3 /*break*/, 2];
                        token = this.configuration.accessToken;
                        return [4 /*yield*/, token("bearerAuth", [])];
                    case 1:
                        tokenString = _a.sent();
                        if (tokenString) {
                            headerParameters["Authorization"] = "Bearer ".concat(tokenString);
                        }
                        _a.label = 2;
                    case 2: return [4 /*yield*/, this.request({
                            path: "/v1/country/currency",
                            method: 'GET',
                            headers: headerParameters,
                            query: queryParameters,
                        }, initOverrides)];
                    case 3:
                        response = _a.sent();
                        return [2 /*return*/, new runtime.JSONApiResponse(response, function (jsonValue) { return (0, index_1.CountryCurrentResponseFromJSON)(jsonValue); })];
                }
            });
        });
    };
    /**
     * Returns currency ISO code for the country the request was sent from. Location is determined using the IP address.
     */
    DefaultApi.prototype.v1CountryCurrencyGet = function (initOverrides) {
        return __awaiter(this, void 0, void 0, function () {
            var response;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0: return [4 /*yield*/, this.v1CountryCurrencyGetRaw(initOverrides)];
                    case 1:
                        response = _a.sent();
                        return [4 /*yield*/, response.value()];
                    case 2: return [2 /*return*/, _a.sent()];
                }
            });
        });
    };
    /**
     * Returns currency conversion factor from one currency to another.
     */
    DefaultApi.prototype.v1CurrencyConversionRateFromIsoCodeToIsoCodeGetRaw = function (requestParameters, initOverrides) {
        return __awaiter(this, void 0, void 0, function () {
            var queryParameters, headerParameters, token, tokenString, response;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        if (requestParameters.fromIsoCode === null || requestParameters.fromIsoCode === undefined) {
                            throw new runtime.RequiredError('fromIsoCode', 'Required parameter requestParameters.fromIsoCode was null or undefined when calling v1CurrencyConversionRateFromIsoCodeToIsoCodeGet.');
                        }
                        if (requestParameters.toIsoCode === null || requestParameters.toIsoCode === undefined) {
                            throw new runtime.RequiredError('toIsoCode', 'Required parameter requestParameters.toIsoCode was null or undefined when calling v1CurrencyConversionRateFromIsoCodeToIsoCodeGet.');
                        }
                        queryParameters = {};
                        headerParameters = {};
                        if (!(this.configuration && this.configuration.accessToken)) return [3 /*break*/, 2];
                        token = this.configuration.accessToken;
                        return [4 /*yield*/, token("bearerAuth", [])];
                    case 1:
                        tokenString = _a.sent();
                        if (tokenString) {
                            headerParameters["Authorization"] = "Bearer ".concat(tokenString);
                        }
                        _a.label = 2;
                    case 2: return [4 /*yield*/, this.request({
                            path: "/v1/currency/conversion-rate/{fromIsoCode}/{toIsoCode}".replace("{".concat("fromIsoCode", "}"), encodeURIComponent(String(requestParameters.fromIsoCode))).replace("{".concat("toIsoCode", "}"), encodeURIComponent(String(requestParameters.toIsoCode))),
                            method: 'GET',
                            headers: headerParameters,
                            query: queryParameters,
                        }, initOverrides)];
                    case 3:
                        response = _a.sent();
                        return [2 /*return*/, new runtime.JSONApiResponse(response, function (jsonValue) { return (0, index_1.CurrencyConversionResponseFromJSON)(jsonValue); })];
                }
            });
        });
    };
    /**
     * Returns currency conversion factor from one currency to another.
     */
    DefaultApi.prototype.v1CurrencyConversionRateFromIsoCodeToIsoCodeGet = function (requestParameters, initOverrides) {
        return __awaiter(this, void 0, void 0, function () {
            var response;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0: return [4 /*yield*/, this.v1CurrencyConversionRateFromIsoCodeToIsoCodeGetRaw(requestParameters, initOverrides)];
                    case 1:
                        response = _a.sent();
                        return [4 /*yield*/, response.value()];
                    case 2: return [2 /*return*/, _a.sent()];
                }
            });
        });
    };
    /**
     * Fetches the available discount cards.
     */
    DefaultApi.prototype.v1DiscountCardsGetRaw = function (initOverrides) {
        return __awaiter(this, void 0, void 0, function () {
            var queryParameters, headerParameters, token, tokenString, response;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        queryParameters = {};
                        headerParameters = {};
                        if (!(this.configuration && this.configuration.accessToken)) return [3 /*break*/, 2];
                        token = this.configuration.accessToken;
                        return [4 /*yield*/, token("bearerAuth", [])];
                    case 1:
                        tokenString = _a.sent();
                        if (tokenString) {
                            headerParameters["Authorization"] = "Bearer ".concat(tokenString);
                        }
                        _a.label = 2;
                    case 2: return [4 /*yield*/, this.request({
                            path: "/v1/discount-cards",
                            method: 'GET',
                            headers: headerParameters,
                            query: queryParameters,
                        }, initOverrides)];
                    case 3:
                        response = _a.sent();
                        return [2 /*return*/, new runtime.JSONApiResponse(response, function (jsonValue) { return (0, index_1.DiscountCardsResponseFromJSON)(jsonValue); })];
                }
            });
        });
    };
    /**
     * Fetches the available discount cards.
     */
    DefaultApi.prototype.v1DiscountCardsGet = function (initOverrides) {
        return __awaiter(this, void 0, void 0, function () {
            var response;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0: return [4 /*yield*/, this.v1DiscountCardsGetRaw(initOverrides)];
                    case 1:
                        response = _a.sent();
                        return [4 /*yield*/, response.value()];
                    case 2: return [2 /*return*/, _a.sent()];
                }
            });
        });
    };
    /**
     * Stores Green Travel Pledge data for train emission calculations.
     */
    DefaultApi.prototype.v1GreenTravelPledgeDataPostRaw = function (requestParameters, initOverrides) {
        return __awaiter(this, void 0, void 0, function () {
            var queryParameters, headerParameters, token, tokenString, response;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        if (requestParameters.body === null || requestParameters.body === undefined) {
                            throw new runtime.RequiredError('body', 'Required parameter requestParameters.body was null or undefined when calling v1GreenTravelPledgeDataPost.');
                        }
                        queryParameters = {};
                        headerParameters = {};
                        headerParameters['Content-Type'] = 'text/csv';
                        if (!(this.configuration && this.configuration.accessToken)) return [3 /*break*/, 2];
                        token = this.configuration.accessToken;
                        return [4 /*yield*/, token("bearerAuth", [])];
                    case 1:
                        tokenString = _a.sent();
                        if (tokenString) {
                            headerParameters["Authorization"] = "Bearer ".concat(tokenString);
                        }
                        _a.label = 2;
                    case 2: return [4 /*yield*/, this.request({
                            path: "/v1/green-travel-pledge-data",
                            method: 'POST',
                            headers: headerParameters,
                            query: queryParameters,
                            body: requestParameters.body,
                        }, initOverrides)];
                    case 3:
                        response = _a.sent();
                        return [2 /*return*/, new runtime.VoidApiResponse(response)];
                }
            });
        });
    };
    /**
     * Stores Green Travel Pledge data for train emission calculations.
     */
    DefaultApi.prototype.v1GreenTravelPledgeDataPost = function (requestParameters, initOverrides) {
        return __awaiter(this, void 0, void 0, function () {
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0: return [4 /*yield*/, this.v1GreenTravelPledgeDataPostRaw(requestParameters, initOverrides)];
                    case 1:
                        _a.sent();
                        return [2 /*return*/];
                }
            });
        });
    };
    /**
     * Returns a list of stations where kiosk tickets can be collected from. Only used for testing.
     */
    DefaultApi.prototype.v1KioskStationsGetRaw = function (initOverrides) {
        return __awaiter(this, void 0, void 0, function () {
            var queryParameters, headerParameters, token, tokenString, response;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        queryParameters = {};
                        headerParameters = {};
                        if (!(this.configuration && this.configuration.accessToken)) return [3 /*break*/, 2];
                        token = this.configuration.accessToken;
                        return [4 /*yield*/, token("bearerAuth", [])];
                    case 1:
                        tokenString = _a.sent();
                        if (tokenString) {
                            headerParameters["Authorization"] = "Bearer ".concat(tokenString);
                        }
                        _a.label = 2;
                    case 2: return [4 /*yield*/, this.request({
                            path: "/v1/kiosk-stations",
                            method: 'GET',
                            headers: headerParameters,
                            query: queryParameters,
                        }, initOverrides)];
                    case 3:
                        response = _a.sent();
                        return [2 /*return*/, new runtime.JSONApiResponse(response, function (jsonValue) { return (0, index_1.KioskStationsResponseFromJSON)(jsonValue); })];
                }
            });
        });
    };
    /**
     * Returns a list of stations where kiosk tickets can be collected from. Only used for testing.
     */
    DefaultApi.prototype.v1KioskStationsGet = function (initOverrides) {
        return __awaiter(this, void 0, void 0, function () {
            var response;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0: return [4 /*yield*/, this.v1KioskStationsGetRaw(initOverrides)];
                    case 1:
                        response = _a.sent();
                        return [4 /*yield*/, response.value()];
                    case 2: return [2 /*return*/, _a.sent()];
                }
            });
        });
    };
    /**
     */
    DefaultApi.prototype.v1LocationQueryGetRaw = function (requestParameters, initOverrides) {
        return __awaiter(this, void 0, void 0, function () {
            var queryParameters, headerParameters, token, tokenString, response;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        if (requestParameters.query === null || requestParameters.query === undefined) {
                            throw new runtime.RequiredError('query', 'Required parameter requestParameters.query was null or undefined when calling v1LocationQueryGet.');
                        }
                        queryParameters = {};
                        headerParameters = {};
                        if (!(this.configuration && this.configuration.accessToken)) return [3 /*break*/, 2];
                        token = this.configuration.accessToken;
                        return [4 /*yield*/, token("bearerAuth", [])];
                    case 1:
                        tokenString = _a.sent();
                        if (tokenString) {
                            headerParameters["Authorization"] = "Bearer ".concat(tokenString);
                        }
                        _a.label = 2;
                    case 2: return [4 /*yield*/, this.request({
                            path: "/v1/location/{query}".replace("{".concat("query", "}"), encodeURIComponent(String(requestParameters.query))),
                            method: 'GET',
                            headers: headerParameters,
                            query: queryParameters,
                        }, initOverrides)];
                    case 3:
                        response = _a.sent();
                        return [2 /*return*/, new runtime.JSONApiResponse(response, function (jsonValue) { return (0, index_1.QueryLocationResponseFromJSON)(jsonValue); })];
                }
            });
        });
    };
    /**
     */
    DefaultApi.prototype.v1LocationQueryGet = function (requestParameters, initOverrides) {
        return __awaiter(this, void 0, void 0, function () {
            var response;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0: return [4 /*yield*/, this.v1LocationQueryGetRaw(requestParameters, initOverrides)];
                    case 1:
                        response = _a.sent();
                        return [4 /*yield*/, response.value()];
                    case 2: return [2 /*return*/, _a.sent()];
                }
            });
        });
    };
    /**
     * Retrieves loyalty programmes that can be associated with a traveller and used on a booking, e.g. a flight loyalty programme such as a with British Airways that provides discounts, extra baggage, etc.
     */
    DefaultApi.prototype.v1LoyaltyProgrammesGetRaw = function (initOverrides) {
        return __awaiter(this, void 0, void 0, function () {
            var queryParameters, headerParameters, token, tokenString, response;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        queryParameters = {};
                        headerParameters = {};
                        if (!(this.configuration && this.configuration.accessToken)) return [3 /*break*/, 2];
                        token = this.configuration.accessToken;
                        return [4 /*yield*/, token("bearerAuth", [])];
                    case 1:
                        tokenString = _a.sent();
                        if (tokenString) {
                            headerParameters["Authorization"] = "Bearer ".concat(tokenString);
                        }
                        _a.label = 2;
                    case 2: return [4 /*yield*/, this.request({
                            path: "/v1/loyalty-programmes",
                            method: 'GET',
                            headers: headerParameters,
                            query: queryParameters,
                        }, initOverrides)];
                    case 3:
                        response = _a.sent();
                        return [2 /*return*/, new runtime.JSONApiResponse(response, function (jsonValue) { return (0, index_1.LoyaltyProgrammeResponseFromJSON)(jsonValue); })];
                }
            });
        });
    };
    /**
     * Retrieves loyalty programmes that can be associated with a traveller and used on a booking, e.g. a flight loyalty programme such as a with British Airways that provides discounts, extra baggage, etc.
     */
    DefaultApi.prototype.v1LoyaltyProgrammesGet = function (initOverrides) {
        return __awaiter(this, void 0, void 0, function () {
            var response;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0: return [4 /*yield*/, this.v1LoyaltyProgrammesGetRaw(initOverrides)];
                    case 1:
                        response = _a.sent();
                        return [4 /*yield*/, response.value()];
                    case 2: return [2 /*return*/, _a.sent()];
                }
            });
        });
    };
    /**
     * Find a pair of train stations between which a train could be used as part of a journey.
     */
    DefaultApi.prototype.v1NearbyStationsPostRaw = function (requestParameters, initOverrides) {
        return __awaiter(this, void 0, void 0, function () {
            var queryParameters, headerParameters, token, tokenString, response;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        if (requestParameters.nearbyTrainStationRequest === null || requestParameters.nearbyTrainStationRequest === undefined) {
                            throw new runtime.RequiredError('nearbyTrainStationRequest', 'Required parameter requestParameters.nearbyTrainStationRequest was null or undefined when calling v1NearbyStationsPost.');
                        }
                        queryParameters = {};
                        headerParameters = {};
                        headerParameters['Content-Type'] = 'application/json';
                        if (!(this.configuration && this.configuration.accessToken)) return [3 /*break*/, 2];
                        token = this.configuration.accessToken;
                        return [4 /*yield*/, token("bearerAuth", [])];
                    case 1:
                        tokenString = _a.sent();
                        if (tokenString) {
                            headerParameters["Authorization"] = "Bearer ".concat(tokenString);
                        }
                        _a.label = 2;
                    case 2: return [4 /*yield*/, this.request({
                            path: "/v1/nearby-stations",
                            method: 'POST',
                            headers: headerParameters,
                            query: queryParameters,
                            body: (0, index_1.NearbyTrainStationRequestToJSON)(requestParameters.nearbyTrainStationRequest),
                        }, initOverrides)];
                    case 3:
                        response = _a.sent();
                        return [2 /*return*/, new runtime.JSONApiResponse(response, function (jsonValue) { return (0, index_1.NearbyTrainStationResponseFromJSON)(jsonValue); })];
                }
            });
        });
    };
    /**
     * Find a pair of train stations between which a train could be used as part of a journey.
     */
    DefaultApi.prototype.v1NearbyStationsPost = function (requestParameters, initOverrides) {
        return __awaiter(this, void 0, void 0, function () {
            var response;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0: return [4 /*yield*/, this.v1NearbyStationsPostRaw(requestParameters, initOverrides)];
                    case 1:
                        response = _a.sent();
                        return [4 /*yield*/, response.value()];
                    case 2: return [2 /*return*/, _a.sent()];
                }
            });
        });
    };
    /**
     * Get the app settings for an organisation. The organisation to return app settings for is detemined by a combination of the auth header on the request, and the urlName query parameter, both of which can be null. If one of these two is provided, we return the app settings associated with the organisation referenced by that one property. If both are provided, we return the app settings for the organisation they reference if they agree, or an error if they do not. If neither are provided, we return the public app settings.
     */
    DefaultApi.prototype.v1OrgsAppSettingsGetRaw = function (requestParameters, initOverrides) {
        return __awaiter(this, void 0, void 0, function () {
            var queryParameters, headerParameters, token, tokenString, response;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        queryParameters = {};
                        if (requestParameters.urlName !== undefined) {
                            queryParameters['urlName'] = requestParameters.urlName;
                        }
                        headerParameters = {};
                        if (!(this.configuration && this.configuration.accessToken)) return [3 /*break*/, 2];
                        token = this.configuration.accessToken;
                        return [4 /*yield*/, token("bearerAuth", [])];
                    case 1:
                        tokenString = _a.sent();
                        if (tokenString) {
                            headerParameters["Authorization"] = "Bearer ".concat(tokenString);
                        }
                        _a.label = 2;
                    case 2: return [4 /*yield*/, this.request({
                            path: "/v1/orgs/app-settings",
                            method: 'GET',
                            headers: headerParameters,
                            query: queryParameters,
                        }, initOverrides)];
                    case 3:
                        response = _a.sent();
                        return [2 /*return*/, new runtime.JSONApiResponse(response, function (jsonValue) { return (0, index_1.OrganisationFrontendDetailsFromJSON)(jsonValue); })];
                }
            });
        });
    };
    /**
     * Get the app settings for an organisation. The organisation to return app settings for is detemined by a combination of the auth header on the request, and the urlName query parameter, both of which can be null. If one of these two is provided, we return the app settings associated with the organisation referenced by that one property. If both are provided, we return the app settings for the organisation they reference if they agree, or an error if they do not. If neither are provided, we return the public app settings.
     */
    DefaultApi.prototype.v1OrgsAppSettingsGet = function () {
        return __awaiter(this, arguments, void 0, function (requestParameters, initOverrides) {
            var response;
            if (requestParameters === void 0) { requestParameters = {}; }
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0: return [4 /*yield*/, this.v1OrgsAppSettingsGetRaw(requestParameters, initOverrides)];
                    case 1:
                        response = _a.sent();
                        return [4 /*yield*/, response.value()];
                    case 2: return [2 /*return*/, _a.sent()];
                }
            });
        });
    };
    /**
     * Fetch a record of all searches that were performed by employees at an organisation.
     */
    DefaultApi.prototype.v1OrgsCustomerByRefRefAnalyticsGetRaw = function (requestParameters, initOverrides) {
        return __awaiter(this, void 0, void 0, function () {
            var queryParameters, headerParameters, token, tokenString, response;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        if (requestParameters.ref === null || requestParameters.ref === undefined) {
                            throw new runtime.RequiredError('ref', 'Required parameter requestParameters.ref was null or undefined when calling v1OrgsCustomerByRefRefAnalyticsGet.');
                        }
                        queryParameters = {};
                        headerParameters = {};
                        if (!(this.configuration && this.configuration.accessToken)) return [3 /*break*/, 2];
                        token = this.configuration.accessToken;
                        return [4 /*yield*/, token("bearerAuth", [])];
                    case 1:
                        tokenString = _a.sent();
                        if (tokenString) {
                            headerParameters["Authorization"] = "Bearer ".concat(tokenString);
                        }
                        _a.label = 2;
                    case 2: return [4 /*yield*/, this.request({
                            path: "/v1/orgs/customer/by-ref/{ref}/analytics".replace("{".concat("ref", "}"), encodeURIComponent(String(requestParameters.ref))),
                            method: 'GET',
                            headers: headerParameters,
                            query: queryParameters,
                        }, initOverrides)];
                    case 3:
                        response = _a.sent();
                        return [2 /*return*/, new runtime.JSONApiResponse(response, function (jsonValue) { return (0, index_1.OrganisationAnalyticsResponseFromJSON)(jsonValue); })];
                }
            });
        });
    };
    /**
     * Fetch a record of all searches that were performed by employees at an organisation.
     */
    DefaultApi.prototype.v1OrgsCustomerByRefRefAnalyticsGet = function (requestParameters, initOverrides) {
        return __awaiter(this, void 0, void 0, function () {
            var response;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0: return [4 /*yield*/, this.v1OrgsCustomerByRefRefAnalyticsGetRaw(requestParameters, initOverrides)];
                    case 1:
                        response = _a.sent();
                        return [4 /*yield*/, response.value()];
                    case 2: return [2 /*return*/, _a.sent()];
                }
            });
        });
    };
    /**
     * Link an RouteZero organisation with a 3rd party \"auth organisation\". For example, an organisation in Auth0, PropelAuth, etc.
     */
    DefaultApi.prototype.v1OrgsCustomerByRefRefAuthPostRaw = function (requestParameters, initOverrides) {
        return __awaiter(this, void 0, void 0, function () {
            var queryParameters, headerParameters, token, tokenString, response;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        if (requestParameters.ref === null || requestParameters.ref === undefined) {
                            throw new runtime.RequiredError('ref', 'Required parameter requestParameters.ref was null or undefined when calling v1OrgsCustomerByRefRefAuthPost.');
                        }
                        if (requestParameters.linkAuthOrganisationRequest === null || requestParameters.linkAuthOrganisationRequest === undefined) {
                            throw new runtime.RequiredError('linkAuthOrganisationRequest', 'Required parameter requestParameters.linkAuthOrganisationRequest was null or undefined when calling v1OrgsCustomerByRefRefAuthPost.');
                        }
                        queryParameters = {};
                        headerParameters = {};
                        headerParameters['Content-Type'] = 'application/json';
                        if (!(this.configuration && this.configuration.accessToken)) return [3 /*break*/, 2];
                        token = this.configuration.accessToken;
                        return [4 /*yield*/, token("bearerAuth", [])];
                    case 1:
                        tokenString = _a.sent();
                        if (tokenString) {
                            headerParameters["Authorization"] = "Bearer ".concat(tokenString);
                        }
                        _a.label = 2;
                    case 2: return [4 /*yield*/, this.request({
                            path: "/v1/orgs/customer/by-ref/{ref}/auth".replace("{".concat("ref", "}"), encodeURIComponent(String(requestParameters.ref))),
                            method: 'POST',
                            headers: headerParameters,
                            query: queryParameters,
                            body: (0, index_1.LinkAuthOrganisationRequestToJSON)(requestParameters.linkAuthOrganisationRequest),
                        }, initOverrides)];
                    case 3:
                        response = _a.sent();
                        return [2 /*return*/, new runtime.VoidApiResponse(response)];
                }
            });
        });
    };
    /**
     * Link an RouteZero organisation with a 3rd party \"auth organisation\". For example, an organisation in Auth0, PropelAuth, etc.
     */
    DefaultApi.prototype.v1OrgsCustomerByRefRefAuthPost = function (requestParameters, initOverrides) {
        return __awaiter(this, void 0, void 0, function () {
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0: return [4 /*yield*/, this.v1OrgsCustomerByRefRefAuthPostRaw(requestParameters, initOverrides)];
                    case 1:
                        _a.sent();
                        return [2 /*return*/];
                }
            });
        });
    };
    /**
     * Enable on account booking for an organisation.
     */
    DefaultApi.prototype.v1OrgsCustomerByRefRefOnAccountBookingPostRaw = function (requestParameters, initOverrides) {
        return __awaiter(this, void 0, void 0, function () {
            var queryParameters, headerParameters, token, tokenString, response;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        if (requestParameters.ref === null || requestParameters.ref === undefined) {
                            throw new runtime.RequiredError('ref', 'Required parameter requestParameters.ref was null or undefined when calling v1OrgsCustomerByRefRefOnAccountBookingPost.');
                        }
                        if (requestParameters.enableOnAccountBookingRequest === null || requestParameters.enableOnAccountBookingRequest === undefined) {
                            throw new runtime.RequiredError('enableOnAccountBookingRequest', 'Required parameter requestParameters.enableOnAccountBookingRequest was null or undefined when calling v1OrgsCustomerByRefRefOnAccountBookingPost.');
                        }
                        queryParameters = {};
                        headerParameters = {};
                        headerParameters['Content-Type'] = 'application/json';
                        if (!(this.configuration && this.configuration.accessToken)) return [3 /*break*/, 2];
                        token = this.configuration.accessToken;
                        return [4 /*yield*/, token("bearerAuth", [])];
                    case 1:
                        tokenString = _a.sent();
                        if (tokenString) {
                            headerParameters["Authorization"] = "Bearer ".concat(tokenString);
                        }
                        _a.label = 2;
                    case 2: return [4 /*yield*/, this.request({
                            path: "/v1/orgs/customer/by-ref/{ref}/on-account-booking".replace("{".concat("ref", "}"), encodeURIComponent(String(requestParameters.ref))),
                            method: 'POST',
                            headers: headerParameters,
                            query: queryParameters,
                            body: (0, index_1.EnableOnAccountBookingRequestToJSON)(requestParameters.enableOnAccountBookingRequest),
                        }, initOverrides)];
                    case 3:
                        response = _a.sent();
                        return [2 /*return*/, new runtime.VoidApiResponse(response)];
                }
            });
        });
    };
    /**
     * Enable on account booking for an organisation.
     */
    DefaultApi.prototype.v1OrgsCustomerByRefRefOnAccountBookingPost = function (requestParameters, initOverrides) {
        return __awaiter(this, void 0, void 0, function () {
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0: return [4 /*yield*/, this.v1OrgsCustomerByRefRefOnAccountBookingPostRaw(requestParameters, initOverrides)];
                    case 1:
                        _a.sent();
                        return [2 /*return*/];
                }
            });
        });
    };
    /**
     * Update organisation details for an organisation referenced by their readable reference (e.g. \"routezero\").
     */
    DefaultApi.prototype.v1OrgsCustomerByRefRefPatchRaw = function (requestParameters, initOverrides) {
        return __awaiter(this, void 0, void 0, function () {
            var queryParameters, headerParameters, token, tokenString, response;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        if (requestParameters.ref === null || requestParameters.ref === undefined) {
                            throw new runtime.RequiredError('ref', 'Required parameter requestParameters.ref was null or undefined when calling v1OrgsCustomerByRefRefPatch.');
                        }
                        if (requestParameters.nullableEditableOrganisationDetails === null || requestParameters.nullableEditableOrganisationDetails === undefined) {
                            throw new runtime.RequiredError('nullableEditableOrganisationDetails', 'Required parameter requestParameters.nullableEditableOrganisationDetails was null or undefined when calling v1OrgsCustomerByRefRefPatch.');
                        }
                        queryParameters = {};
                        headerParameters = {};
                        headerParameters['Content-Type'] = 'application/json';
                        if (!(this.configuration && this.configuration.accessToken)) return [3 /*break*/, 2];
                        token = this.configuration.accessToken;
                        return [4 /*yield*/, token("bearerAuth", [])];
                    case 1:
                        tokenString = _a.sent();
                        if (tokenString) {
                            headerParameters["Authorization"] = "Bearer ".concat(tokenString);
                        }
                        _a.label = 2;
                    case 2: return [4 /*yield*/, this.request({
                            path: "/v1/orgs/customer/by-ref/{ref}".replace("{".concat("ref", "}"), encodeURIComponent(String(requestParameters.ref))),
                            method: 'PATCH',
                            headers: headerParameters,
                            query: queryParameters,
                            body: (0, index_1.NullableEditableOrganisationDetailsToJSON)(requestParameters.nullableEditableOrganisationDetails),
                        }, initOverrides)];
                    case 3:
                        response = _a.sent();
                        return [2 /*return*/, new runtime.JSONApiResponse(response, function (jsonValue) { return (0, index_1.OrganisationFromJSON)(jsonValue); })];
                }
            });
        });
    };
    /**
     * Update organisation details for an organisation referenced by their readable reference (e.g. \"routezero\").
     */
    DefaultApi.prototype.v1OrgsCustomerByRefRefPatch = function (requestParameters, initOverrides) {
        return __awaiter(this, void 0, void 0, function () {
            var response;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0: return [4 /*yield*/, this.v1OrgsCustomerByRefRefPatchRaw(requestParameters, initOverrides)];
                    case 1:
                        response = _a.sent();
                        return [4 /*yield*/, response.value()];
                    case 2: return [2 /*return*/, _a.sent()];
                }
            });
        });
    };
    /**
     * Update the pricing strategies for different travel methods for an organisation.
     */
    DefaultApi.prototype.v1OrgsCustomerByRefRefPricingStrategiesPutRaw = function (requestParameters, initOverrides) {
        return __awaiter(this, void 0, void 0, function () {
            var queryParameters, headerParameters, token, tokenString, response;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        if (requestParameters.ref === null || requestParameters.ref === undefined) {
                            throw new runtime.RequiredError('ref', 'Required parameter requestParameters.ref was null or undefined when calling v1OrgsCustomerByRefRefPricingStrategiesPut.');
                        }
                        if (requestParameters.travelProviderPriceStrategies === null || requestParameters.travelProviderPriceStrategies === undefined) {
                            throw new runtime.RequiredError('travelProviderPriceStrategies', 'Required parameter requestParameters.travelProviderPriceStrategies was null or undefined when calling v1OrgsCustomerByRefRefPricingStrategiesPut.');
                        }
                        queryParameters = {};
                        headerParameters = {};
                        headerParameters['Content-Type'] = 'application/json';
                        if (!(this.configuration && this.configuration.accessToken)) return [3 /*break*/, 2];
                        token = this.configuration.accessToken;
                        return [4 /*yield*/, token("bearerAuth", [])];
                    case 1:
                        tokenString = _a.sent();
                        if (tokenString) {
                            headerParameters["Authorization"] = "Bearer ".concat(tokenString);
                        }
                        _a.label = 2;
                    case 2: return [4 /*yield*/, this.request({
                            path: "/v1/orgs/customer/by-ref/{ref}/pricing-strategies".replace("{".concat("ref", "}"), encodeURIComponent(String(requestParameters.ref))),
                            method: 'PUT',
                            headers: headerParameters,
                            query: queryParameters,
                            body: (0, index_1.TravelProviderPriceStrategiesToJSON)(requestParameters.travelProviderPriceStrategies),
                        }, initOverrides)];
                    case 3:
                        response = _a.sent();
                        return [2 /*return*/, new runtime.VoidApiResponse(response)];
                }
            });
        });
    };
    /**
     * Update the pricing strategies for different travel methods for an organisation.
     */
    DefaultApi.prototype.v1OrgsCustomerByRefRefPricingStrategiesPut = function (requestParameters, initOverrides) {
        return __awaiter(this, void 0, void 0, function () {
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0: return [4 /*yield*/, this.v1OrgsCustomerByRefRefPricingStrategiesPutRaw(requestParameters, initOverrides)];
                    case 1:
                        _a.sent();
                        return [2 /*return*/];
                }
            });
        });
    };
    /**
     * Enable purchase order number required for an organisation.
     */
    DefaultApi.prototype.v1OrgsCustomerByRefRefPurchaseOrderNumberPostRaw = function (requestParameters, initOverrides) {
        return __awaiter(this, void 0, void 0, function () {
            var queryParameters, headerParameters, token, tokenString, response;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        if (requestParameters.ref === null || requestParameters.ref === undefined) {
                            throw new runtime.RequiredError('ref', 'Required parameter requestParameters.ref was null or undefined when calling v1OrgsCustomerByRefRefPurchaseOrderNumberPost.');
                        }
                        queryParameters = {};
                        headerParameters = {};
                        if (!(this.configuration && this.configuration.accessToken)) return [3 /*break*/, 2];
                        token = this.configuration.accessToken;
                        return [4 /*yield*/, token("bearerAuth", [])];
                    case 1:
                        tokenString = _a.sent();
                        if (tokenString) {
                            headerParameters["Authorization"] = "Bearer ".concat(tokenString);
                        }
                        _a.label = 2;
                    case 2: return [4 /*yield*/, this.request({
                            path: "/v1/orgs/customer/by-ref/{ref}/purchase-order-number".replace("{".concat("ref", "}"), encodeURIComponent(String(requestParameters.ref))),
                            method: 'POST',
                            headers: headerParameters,
                            query: queryParameters,
                        }, initOverrides)];
                    case 3:
                        response = _a.sent();
                        return [2 /*return*/, new runtime.VoidApiResponse(response)];
                }
            });
        });
    };
    /**
     * Enable purchase order number required for an organisation.
     */
    DefaultApi.prototype.v1OrgsCustomerByRefRefPurchaseOrderNumberPost = function (requestParameters, initOverrides) {
        return __awaiter(this, void 0, void 0, function () {
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0: return [4 /*yield*/, this.v1OrgsCustomerByRefRefPurchaseOrderNumberPostRaw(requestParameters, initOverrides)];
                    case 1:
                        _a.sent();
                        return [2 /*return*/];
                }
            });
        });
    };
    /**
     * Enable train booking for a given organisation, referenced by their readable reference (e.g. \"routezero\"). This will register the organisation with Trainline, who provide train booking through our partnership.
     */
    DefaultApi.prototype.v1OrgsCustomerByRefRefTrainlinePostRaw = function (requestParameters, initOverrides) {
        return __awaiter(this, void 0, void 0, function () {
            var queryParameters, headerParameters, token, tokenString, response;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        if (requestParameters.ref === null || requestParameters.ref === undefined) {
                            throw new runtime.RequiredError('ref', 'Required parameter requestParameters.ref was null or undefined when calling v1OrgsCustomerByRefRefTrainlinePost.');
                        }
                        if (requestParameters.enableTrainlineRequest === null || requestParameters.enableTrainlineRequest === undefined) {
                            throw new runtime.RequiredError('enableTrainlineRequest', 'Required parameter requestParameters.enableTrainlineRequest was null or undefined when calling v1OrgsCustomerByRefRefTrainlinePost.');
                        }
                        queryParameters = {};
                        headerParameters = {};
                        headerParameters['Content-Type'] = 'application/json';
                        if (!(this.configuration && this.configuration.accessToken)) return [3 /*break*/, 2];
                        token = this.configuration.accessToken;
                        return [4 /*yield*/, token("bearerAuth", [])];
                    case 1:
                        tokenString = _a.sent();
                        if (tokenString) {
                            headerParameters["Authorization"] = "Bearer ".concat(tokenString);
                        }
                        _a.label = 2;
                    case 2: return [4 /*yield*/, this.request({
                            path: "/v1/orgs/customer/by-ref/{ref}/trainline".replace("{".concat("ref", "}"), encodeURIComponent(String(requestParameters.ref))),
                            method: 'POST',
                            headers: headerParameters,
                            query: queryParameters,
                            body: (0, index_1.EnableTrainlineRequestToJSON)(requestParameters.enableTrainlineRequest),
                        }, initOverrides)];
                    case 3:
                        response = _a.sent();
                        return [2 /*return*/, new runtime.JSONApiResponse(response, function (jsonValue) { return (0, index_1.OrganisationFromJSON)(jsonValue); })];
                }
            });
        });
    };
    /**
     * Enable train booking for a given organisation, referenced by their readable reference (e.g. \"routezero\"). This will register the organisation with Trainline, who provide train booking through our partnership.
     */
    DefaultApi.prototype.v1OrgsCustomerByRefRefTrainlinePost = function (requestParameters, initOverrides) {
        return __awaiter(this, void 0, void 0, function () {
            var response;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0: return [4 /*yield*/, this.v1OrgsCustomerByRefRefTrainlinePostRaw(requestParameters, initOverrides)];
                    case 1:
                        response = _a.sent();
                        return [4 /*yield*/, response.value()];
                    case 2: return [2 /*return*/, _a.sent()];
                }
            });
        });
    };
    /**
     * Return the URL to redirect a user to for them to log in. This can be organisation specific based on the OrgUrlName in the LoginUrlRequest.
     */
    DefaultApi.prototype.v1OrgsCustomerLoginUrlPostRaw = function (requestParameters, initOverrides) {
        return __awaiter(this, void 0, void 0, function () {
            var queryParameters, headerParameters, token, tokenString, response;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        if (requestParameters.loginUrlRequest === null || requestParameters.loginUrlRequest === undefined) {
                            throw new runtime.RequiredError('loginUrlRequest', 'Required parameter requestParameters.loginUrlRequest was null or undefined when calling v1OrgsCustomerLoginUrlPost.');
                        }
                        queryParameters = {};
                        headerParameters = {};
                        headerParameters['Content-Type'] = 'application/json';
                        if (!(this.configuration && this.configuration.accessToken)) return [3 /*break*/, 2];
                        token = this.configuration.accessToken;
                        return [4 /*yield*/, token("bearerAuth", [])];
                    case 1:
                        tokenString = _a.sent();
                        if (tokenString) {
                            headerParameters["Authorization"] = "Bearer ".concat(tokenString);
                        }
                        _a.label = 2;
                    case 2: return [4 /*yield*/, this.request({
                            path: "/v1/orgs/customer/login-url",
                            method: 'POST',
                            headers: headerParameters,
                            query: queryParameters,
                            body: (0, index_1.LoginUrlRequestToJSON)(requestParameters.loginUrlRequest),
                        }, initOverrides)];
                    case 3:
                        response = _a.sent();
                        return [2 /*return*/, new runtime.JSONApiResponse(response, function (jsonValue) { return (0, index_1.LoginUrlResponseFromJSON)(jsonValue); })];
                }
            });
        });
    };
    /**
     * Return the URL to redirect a user to for them to log in. This can be organisation specific based on the OrgUrlName in the LoginUrlRequest.
     */
    DefaultApi.prototype.v1OrgsCustomerLoginUrlPost = function (requestParameters, initOverrides) {
        return __awaiter(this, void 0, void 0, function () {
            var response;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0: return [4 /*yield*/, this.v1OrgsCustomerLoginUrlPostRaw(requestParameters, initOverrides)];
                    case 1:
                        response = _a.sent();
                        return [4 /*yield*/, response.value()];
                    case 2: return [2 /*return*/, _a.sent()];
                }
            });
        });
    };
    /**
     * Registers a company with RouteZero, and any 3rd party booking services required to provide booking capabilities. For example, if the organisation would like their staff to book trains, then Trainline registration details can be provided.
     */
    DefaultApi.prototype.v1OrgsPostRaw = function (requestParameters, initOverrides) {
        return __awaiter(this, void 0, void 0, function () {
            var queryParameters, headerParameters, token, tokenString, response;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        if (requestParameters.editableOrganisationDetails === null || requestParameters.editableOrganisationDetails === undefined) {
                            throw new runtime.RequiredError('editableOrganisationDetails', 'Required parameter requestParameters.editableOrganisationDetails was null or undefined when calling v1OrgsPost.');
                        }
                        queryParameters = {};
                        headerParameters = {};
                        headerParameters['Content-Type'] = 'application/json';
                        if (!(this.configuration && this.configuration.accessToken)) return [3 /*break*/, 2];
                        token = this.configuration.accessToken;
                        return [4 /*yield*/, token("bearerAuth", [])];
                    case 1:
                        tokenString = _a.sent();
                        if (tokenString) {
                            headerParameters["Authorization"] = "Bearer ".concat(tokenString);
                        }
                        _a.label = 2;
                    case 2: return [4 /*yield*/, this.request({
                            path: "/v1/orgs",
                            method: 'POST',
                            headers: headerParameters,
                            query: queryParameters,
                            body: (0, index_1.EditableOrganisationDetailsToJSON)(requestParameters.editableOrganisationDetails),
                        }, initOverrides)];
                    case 3:
                        response = _a.sent();
                        return [2 /*return*/, new runtime.JSONApiResponse(response, function (jsonValue) { return (0, index_1.OrganisationFromJSON)(jsonValue); })];
                }
            });
        });
    };
    /**
     * Registers a company with RouteZero, and any 3rd party booking services required to provide booking capabilities. For example, if the organisation would like their staff to book trains, then Trainline registration details can be provided.
     */
    DefaultApi.prototype.v1OrgsPost = function (requestParameters, initOverrides) {
        return __awaiter(this, void 0, void 0, function () {
            var response;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0: return [4 /*yield*/, this.v1OrgsPostRaw(requestParameters, initOverrides)];
                    case 1:
                        response = _a.sent();
                        return [4 /*yield*/, response.value()];
                    case 2: return [2 /*return*/, _a.sent()];
                }
            });
        });
    };
    /**
     * Fetch a record of all searches that were performed on the public site.
     */
    DefaultApi.prototype.v1OrgsPublicAnalyticsGetRaw = function (initOverrides) {
        return __awaiter(this, void 0, void 0, function () {
            var queryParameters, headerParameters, token, tokenString, response;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        queryParameters = {};
                        headerParameters = {};
                        if (!(this.configuration && this.configuration.accessToken)) return [3 /*break*/, 2];
                        token = this.configuration.accessToken;
                        return [4 /*yield*/, token("bearerAuth", [])];
                    case 1:
                        tokenString = _a.sent();
                        if (tokenString) {
                            headerParameters["Authorization"] = "Bearer ".concat(tokenString);
                        }
                        _a.label = 2;
                    case 2: return [4 /*yield*/, this.request({
                            path: "/v1/orgs/public/analytics",
                            method: 'GET',
                            headers: headerParameters,
                            query: queryParameters,
                        }, initOverrides)];
                    case 3:
                        response = _a.sent();
                        return [2 /*return*/, new runtime.JSONApiResponse(response, function (jsonValue) { return (0, index_1.OrganisationAnalyticsResponseFromJSON)(jsonValue); })];
                }
            });
        });
    };
    /**
     * Fetch a record of all searches that were performed on the public site.
     */
    DefaultApi.prototype.v1OrgsPublicAnalyticsGet = function (initOverrides) {
        return __awaiter(this, void 0, void 0, function () {
            var response;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0: return [4 /*yield*/, this.v1OrgsPublicAnalyticsGetRaw(initOverrides)];
                    case 1:
                        response = _a.sent();
                        return [4 /*yield*/, response.value()];
                    case 2: return [2 /*return*/, _a.sent()];
                }
            });
        });
    };
    /**
     * Link an RouteZero organisation with a 3rd party \"auth organisation\". For example, an organisation in Auth0, PropelAuth, etc.
     */
    DefaultApi.prototype.v1OrgsPublicAuthPostRaw = function (requestParameters, initOverrides) {
        return __awaiter(this, void 0, void 0, function () {
            var queryParameters, headerParameters, token, tokenString, response;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        if (requestParameters.linkAuthOrganisationRequest === null || requestParameters.linkAuthOrganisationRequest === undefined) {
                            throw new runtime.RequiredError('linkAuthOrganisationRequest', 'Required parameter requestParameters.linkAuthOrganisationRequest was null or undefined when calling v1OrgsPublicAuthPost.');
                        }
                        queryParameters = {};
                        headerParameters = {};
                        headerParameters['Content-Type'] = 'application/json';
                        if (!(this.configuration && this.configuration.accessToken)) return [3 /*break*/, 2];
                        token = this.configuration.accessToken;
                        return [4 /*yield*/, token("bearerAuth", [])];
                    case 1:
                        tokenString = _a.sent();
                        if (tokenString) {
                            headerParameters["Authorization"] = "Bearer ".concat(tokenString);
                        }
                        _a.label = 2;
                    case 2: return [4 /*yield*/, this.request({
                            path: "/v1/orgs/public/auth",
                            method: 'POST',
                            headers: headerParameters,
                            query: queryParameters,
                            body: (0, index_1.LinkAuthOrganisationRequestToJSON)(requestParameters.linkAuthOrganisationRequest),
                        }, initOverrides)];
                    case 3:
                        response = _a.sent();
                        return [2 /*return*/, new runtime.VoidApiResponse(response)];
                }
            });
        });
    };
    /**
     * Link an RouteZero organisation with a 3rd party \"auth organisation\". For example, an organisation in Auth0, PropelAuth, etc.
     */
    DefaultApi.prototype.v1OrgsPublicAuthPost = function (requestParameters, initOverrides) {
        return __awaiter(this, void 0, void 0, function () {
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0: return [4 /*yield*/, this.v1OrgsPublicAuthPostRaw(requestParameters, initOverrides)];
                    case 1:
                        _a.sent();
                        return [2 /*return*/];
                }
            });
        });
    };
    /**
     * Enable on account booking for an organisation.
     */
    DefaultApi.prototype.v1OrgsPublicOnAccountBookingPostRaw = function (requestParameters, initOverrides) {
        return __awaiter(this, void 0, void 0, function () {
            var queryParameters, headerParameters, token, tokenString, response;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        if (requestParameters.enableOnAccountBookingRequest === null || requestParameters.enableOnAccountBookingRequest === undefined) {
                            throw new runtime.RequiredError('enableOnAccountBookingRequest', 'Required parameter requestParameters.enableOnAccountBookingRequest was null or undefined when calling v1OrgsPublicOnAccountBookingPost.');
                        }
                        queryParameters = {};
                        headerParameters = {};
                        headerParameters['Content-Type'] = 'application/json';
                        if (!(this.configuration && this.configuration.accessToken)) return [3 /*break*/, 2];
                        token = this.configuration.accessToken;
                        return [4 /*yield*/, token("bearerAuth", [])];
                    case 1:
                        tokenString = _a.sent();
                        if (tokenString) {
                            headerParameters["Authorization"] = "Bearer ".concat(tokenString);
                        }
                        _a.label = 2;
                    case 2: return [4 /*yield*/, this.request({
                            path: "/v1/orgs/public/on-account-booking",
                            method: 'POST',
                            headers: headerParameters,
                            query: queryParameters,
                            body: (0, index_1.EnableOnAccountBookingRequestToJSON)(requestParameters.enableOnAccountBookingRequest),
                        }, initOverrides)];
                    case 3:
                        response = _a.sent();
                        return [2 /*return*/, new runtime.VoidApiResponse(response)];
                }
            });
        });
    };
    /**
     * Enable on account booking for an organisation.
     */
    DefaultApi.prototype.v1OrgsPublicOnAccountBookingPost = function (requestParameters, initOverrides) {
        return __awaiter(this, void 0, void 0, function () {
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0: return [4 /*yield*/, this.v1OrgsPublicOnAccountBookingPostRaw(requestParameters, initOverrides)];
                    case 1:
                        _a.sent();
                        return [2 /*return*/];
                }
            });
        });
    };
    /**
     * Update the pricing strategies for the public site.
     */
    DefaultApi.prototype.v1OrgsPublicPricingStrategiesPutRaw = function (requestParameters, initOverrides) {
        return __awaiter(this, void 0, void 0, function () {
            var queryParameters, headerParameters, token, tokenString, response;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        if (requestParameters.travelProviderPriceStrategies === null || requestParameters.travelProviderPriceStrategies === undefined) {
                            throw new runtime.RequiredError('travelProviderPriceStrategies', 'Required parameter requestParameters.travelProviderPriceStrategies was null or undefined when calling v1OrgsPublicPricingStrategiesPut.');
                        }
                        queryParameters = {};
                        headerParameters = {};
                        headerParameters['Content-Type'] = 'application/json';
                        if (!(this.configuration && this.configuration.accessToken)) return [3 /*break*/, 2];
                        token = this.configuration.accessToken;
                        return [4 /*yield*/, token("bearerAuth", [])];
                    case 1:
                        tokenString = _a.sent();
                        if (tokenString) {
                            headerParameters["Authorization"] = "Bearer ".concat(tokenString);
                        }
                        _a.label = 2;
                    case 2: return [4 /*yield*/, this.request({
                            path: "/v1/orgs/public/pricing-strategies",
                            method: 'PUT',
                            headers: headerParameters,
                            query: queryParameters,
                            body: (0, index_1.TravelProviderPriceStrategiesToJSON)(requestParameters.travelProviderPriceStrategies),
                        }, initOverrides)];
                    case 3:
                        response = _a.sent();
                        return [2 /*return*/, new runtime.VoidApiResponse(response)];
                }
            });
        });
    };
    /**
     * Update the pricing strategies for the public site.
     */
    DefaultApi.prototype.v1OrgsPublicPricingStrategiesPut = function (requestParameters, initOverrides) {
        return __awaiter(this, void 0, void 0, function () {
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0: return [4 /*yield*/, this.v1OrgsPublicPricingStrategiesPutRaw(requestParameters, initOverrides)];
                    case 1:
                        _a.sent();
                        return [2 /*return*/];
                }
            });
        });
    };
    /**
     * Enable purchase order number required for an organisation.
     */
    DefaultApi.prototype.v1OrgsPublicPurchaseOrderNumberPostRaw = function (initOverrides) {
        return __awaiter(this, void 0, void 0, function () {
            var queryParameters, headerParameters, token, tokenString, response;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        queryParameters = {};
                        headerParameters = {};
                        if (!(this.configuration && this.configuration.accessToken)) return [3 /*break*/, 2];
                        token = this.configuration.accessToken;
                        return [4 /*yield*/, token("bearerAuth", [])];
                    case 1:
                        tokenString = _a.sent();
                        if (tokenString) {
                            headerParameters["Authorization"] = "Bearer ".concat(tokenString);
                        }
                        _a.label = 2;
                    case 2: return [4 /*yield*/, this.request({
                            path: "/v1/orgs/public/purchase-order-number",
                            method: 'POST',
                            headers: headerParameters,
                            query: queryParameters,
                        }, initOverrides)];
                    case 3:
                        response = _a.sent();
                        return [2 /*return*/, new runtime.VoidApiResponse(response)];
                }
            });
        });
    };
    /**
     * Enable purchase order number required for an organisation.
     */
    DefaultApi.prototype.v1OrgsPublicPurchaseOrderNumberPost = function (initOverrides) {
        return __awaiter(this, void 0, void 0, function () {
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0: return [4 /*yield*/, this.v1OrgsPublicPurchaseOrderNumberPostRaw(initOverrides)];
                    case 1:
                        _a.sent();
                        return [2 /*return*/];
                }
            });
        });
    };
    /**
     * Enable train booking for the public site. This will register the public site with Trainline, who provide train booking through our partnership.
     */
    DefaultApi.prototype.v1OrgsPublicTrainlinePostRaw = function (requestParameters, initOverrides) {
        return __awaiter(this, void 0, void 0, function () {
            var queryParameters, headerParameters, token, tokenString, response;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        if (requestParameters.enableTrainlineRequest === null || requestParameters.enableTrainlineRequest === undefined) {
                            throw new runtime.RequiredError('enableTrainlineRequest', 'Required parameter requestParameters.enableTrainlineRequest was null or undefined when calling v1OrgsPublicTrainlinePost.');
                        }
                        queryParameters = {};
                        headerParameters = {};
                        headerParameters['Content-Type'] = 'application/json';
                        if (!(this.configuration && this.configuration.accessToken)) return [3 /*break*/, 2];
                        token = this.configuration.accessToken;
                        return [4 /*yield*/, token("bearerAuth", [])];
                    case 1:
                        tokenString = _a.sent();
                        if (tokenString) {
                            headerParameters["Authorization"] = "Bearer ".concat(tokenString);
                        }
                        _a.label = 2;
                    case 2: return [4 /*yield*/, this.request({
                            path: "/v1/orgs/public/trainline",
                            method: 'POST',
                            headers: headerParameters,
                            query: queryParameters,
                            body: (0, index_1.EnableTrainlineRequestToJSON)(requestParameters.enableTrainlineRequest),
                        }, initOverrides)];
                    case 3:
                        response = _a.sent();
                        return [2 /*return*/, new runtime.JSONApiResponse(response, function (jsonValue) { return (0, index_1.OrganisationFromJSON)(jsonValue); })];
                }
            });
        });
    };
    /**
     * Enable train booking for the public site. This will register the public site with Trainline, who provide train booking through our partnership.
     */
    DefaultApi.prototype.v1OrgsPublicTrainlinePost = function (requestParameters, initOverrides) {
        return __awaiter(this, void 0, void 0, function () {
            var response;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0: return [4 /*yield*/, this.v1OrgsPublicTrainlinePostRaw(requestParameters, initOverrides)];
                    case 1:
                        response = _a.sent();
                        return [4 /*yield*/, response.value()];
                    case 2: return [2 /*return*/, _a.sent()];
                }
            });
        });
    };
    /**
     * Update organisation details for an the public site.
     */
    DefaultApi.prototype.v1OrgsPublicUpdateDetailsPatchRaw = function (requestParameters, initOverrides) {
        return __awaiter(this, void 0, void 0, function () {
            var queryParameters, headerParameters, token, tokenString, response;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        if (requestParameters.nullableEditableOrganisationDetails === null || requestParameters.nullableEditableOrganisationDetails === undefined) {
                            throw new runtime.RequiredError('nullableEditableOrganisationDetails', 'Required parameter requestParameters.nullableEditableOrganisationDetails was null or undefined when calling v1OrgsPublicUpdateDetailsPatch.');
                        }
                        queryParameters = {};
                        headerParameters = {};
                        headerParameters['Content-Type'] = 'application/json';
                        if (!(this.configuration && this.configuration.accessToken)) return [3 /*break*/, 2];
                        token = this.configuration.accessToken;
                        return [4 /*yield*/, token("bearerAuth", [])];
                    case 1:
                        tokenString = _a.sent();
                        if (tokenString) {
                            headerParameters["Authorization"] = "Bearer ".concat(tokenString);
                        }
                        _a.label = 2;
                    case 2: return [4 /*yield*/, this.request({
                            path: "/v1/orgs/public/update-details",
                            method: 'PATCH',
                            headers: headerParameters,
                            query: queryParameters,
                            body: (0, index_1.NullableEditableOrganisationDetailsToJSON)(requestParameters.nullableEditableOrganisationDetails),
                        }, initOverrides)];
                    case 3:
                        response = _a.sent();
                        return [2 /*return*/, new runtime.JSONApiResponse(response, function (jsonValue) { return (0, index_1.OrganisationFromJSON)(jsonValue); })];
                }
            });
        });
    };
    /**
     * Update organisation details for an the public site.
     */
    DefaultApi.prototype.v1OrgsPublicUpdateDetailsPatch = function (requestParameters, initOverrides) {
        return __awaiter(this, void 0, void 0, function () {
            var response;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0: return [4 /*yield*/, this.v1OrgsPublicUpdateDetailsPatchRaw(requestParameters, initOverrides)];
                    case 1:
                        response = _a.sent();
                        return [4 /*yield*/, response.value()];
                    case 2: return [2 /*return*/, _a.sent()];
                }
            });
        });
    };
    /**
     * Stores a reported problem on the backend. A user can enter text to send and additional information about their searched journey is also sent.
     */
    DefaultApi.prototype.v1ProblemPostRaw = function (requestParameters, initOverrides) {
        return __awaiter(this, void 0, void 0, function () {
            var queryParameters, headerParameters, response;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        if (requestParameters.recordProblemRequest === null || requestParameters.recordProblemRequest === undefined) {
                            throw new runtime.RequiredError('recordProblemRequest', 'Required parameter requestParameters.recordProblemRequest was null or undefined when calling v1ProblemPost.');
                        }
                        queryParameters = {};
                        headerParameters = {};
                        headerParameters['Content-Type'] = 'application/json';
                        return [4 /*yield*/, this.request({
                                path: "/v1/problem",
                                method: 'POST',
                                headers: headerParameters,
                                query: queryParameters,
                                body: (0, index_1.RecordProblemRequestToJSON)(requestParameters.recordProblemRequest),
                            }, initOverrides)];
                    case 1:
                        response = _a.sent();
                        return [2 /*return*/, new runtime.VoidApiResponse(response)];
                }
            });
        });
    };
    /**
     * Stores a reported problem on the backend. A user can enter text to send and additional information about their searched journey is also sent.
     */
    DefaultApi.prototype.v1ProblemPost = function (requestParameters, initOverrides) {
        return __awaiter(this, void 0, void 0, function () {
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0: return [4 /*yield*/, this.v1ProblemPostRaw(requestParameters, initOverrides)];
                    case 1:
                        _a.sent();
                        return [2 /*return*/];
                }
            });
        });
    };
    /**
     * Register that a potential customer is interested in RouteZero. Using their provided details, a representative from RouteZero can reach out.
     */
    DefaultApi.prototype.v1ProspectPostRaw = function (requestParameters, initOverrides) {
        return __awaiter(this, void 0, void 0, function () {
            var queryParameters, headerParameters, response;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        if (requestParameters.recordProspectRequest === null || requestParameters.recordProspectRequest === undefined) {
                            throw new runtime.RequiredError('recordProspectRequest', 'Required parameter requestParameters.recordProspectRequest was null or undefined when calling v1ProspectPost.');
                        }
                        queryParameters = {};
                        headerParameters = {};
                        headerParameters['Content-Type'] = 'application/json';
                        return [4 /*yield*/, this.request({
                                path: "/v1/prospect",
                                method: 'POST',
                                headers: headerParameters,
                                query: queryParameters,
                                body: (0, index_1.RecordProspectRequestToJSON)(requestParameters.recordProspectRequest),
                            }, initOverrides)];
                    case 1:
                        response = _a.sent();
                        return [2 /*return*/, new runtime.VoidApiResponse(response)];
                }
            });
        });
    };
    /**
     * Register that a potential customer is interested in RouteZero. Using their provided details, a representative from RouteZero can reach out.
     */
    DefaultApi.prototype.v1ProspectPost = function (requestParameters, initOverrides) {
        return __awaiter(this, void 0, void 0, function () {
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0: return [4 /*yield*/, this.v1ProspectPostRaw(requestParameters, initOverrides)];
                    case 1:
                        _a.sent();
                        return [2 /*return*/];
                }
            });
        });
    };
    /**
     * Record a users comments.
     */
    DefaultApi.prototype.v1QuestionnaireCommentsPostRaw = function (requestParameters, initOverrides) {
        return __awaiter(this, void 0, void 0, function () {
            var queryParameters, headerParameters, response;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        if (requestParameters.answerCommentsRequest === null || requestParameters.answerCommentsRequest === undefined) {
                            throw new runtime.RequiredError('answerCommentsRequest', 'Required parameter requestParameters.answerCommentsRequest was null or undefined when calling v1QuestionnaireCommentsPost.');
                        }
                        queryParameters = {};
                        headerParameters = {};
                        headerParameters['Content-Type'] = 'application/json';
                        return [4 /*yield*/, this.request({
                                path: "/v1/questionnaire/comments",
                                method: 'POST',
                                headers: headerParameters,
                                query: queryParameters,
                                body: (0, index_1.AnswerCommentsRequestToJSON)(requestParameters.answerCommentsRequest),
                            }, initOverrides)];
                    case 1:
                        response = _a.sent();
                        return [2 /*return*/, new runtime.JSONApiResponse(response, function (jsonValue) { return (0, index_1.AnswerCommentsResponseFromJSON)(jsonValue); })];
                }
            });
        });
    };
    /**
     * Record a users comments.
     */
    DefaultApi.prototype.v1QuestionnaireCommentsPost = function (requestParameters, initOverrides) {
        return __awaiter(this, void 0, void 0, function () {
            var response;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0: return [4 /*yield*/, this.v1QuestionnaireCommentsPostRaw(requestParameters, initOverrides)];
                    case 1:
                        response = _a.sent();
                        return [4 /*yield*/, response.value()];
                    case 2: return [2 /*return*/, _a.sent()];
                }
            });
        });
    };
    /**
     * Record a users product market fit answer.
     */
    DefaultApi.prototype.v1QuestionnaireProductMarketFitPostRaw = function (requestParameters, initOverrides) {
        return __awaiter(this, void 0, void 0, function () {
            var queryParameters, headerParameters, response;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        if (requestParameters.answerProductMarketFitRequest === null || requestParameters.answerProductMarketFitRequest === undefined) {
                            throw new runtime.RequiredError('answerProductMarketFitRequest', 'Required parameter requestParameters.answerProductMarketFitRequest was null or undefined when calling v1QuestionnaireProductMarketFitPost.');
                        }
                        queryParameters = {};
                        headerParameters = {};
                        headerParameters['Content-Type'] = 'application/json';
                        return [4 /*yield*/, this.request({
                                path: "/v1/questionnaire/product-market-fit",
                                method: 'POST',
                                headers: headerParameters,
                                query: queryParameters,
                                body: (0, index_1.AnswerProductMarketFitRequestToJSON)(requestParameters.answerProductMarketFitRequest),
                            }, initOverrides)];
                    case 1:
                        response = _a.sent();
                        return [2 /*return*/, new runtime.JSONApiResponse(response, function (jsonValue) { return (0, index_1.AnswerProductMarketFitResponseFromJSON)(jsonValue); })];
                }
            });
        });
    };
    /**
     * Record a users product market fit answer.
     */
    DefaultApi.prototype.v1QuestionnaireProductMarketFitPost = function (requestParameters, initOverrides) {
        return __awaiter(this, void 0, void 0, function () {
            var response;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0: return [4 /*yield*/, this.v1QuestionnaireProductMarketFitPostRaw(requestParameters, initOverrides)];
                    case 1:
                        response = _a.sent();
                        return [4 /*yield*/, response.value()];
                    case 2: return [2 /*return*/, _a.sent()];
                }
            });
        });
    };
    /**
     * Search for inward plane journeys.
     */
    DefaultApi.prototype.v1SearchInwardPlanePostRaw = function (requestParameters, initOverrides) {
        return __awaiter(this, void 0, void 0, function () {
            var queryParameters, headerParameters, token, tokenString, response;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        if (requestParameters.inwardSearchRequest === null || requestParameters.inwardSearchRequest === undefined) {
                            throw new runtime.RequiredError('inwardSearchRequest', 'Required parameter requestParameters.inwardSearchRequest was null or undefined when calling v1SearchInwardPlanePost.');
                        }
                        queryParameters = {};
                        headerParameters = {};
                        headerParameters['Content-Type'] = 'application/json';
                        if (!(this.configuration && this.configuration.accessToken)) return [3 /*break*/, 2];
                        token = this.configuration.accessToken;
                        return [4 /*yield*/, token("bearerAuth", [])];
                    case 1:
                        tokenString = _a.sent();
                        if (tokenString) {
                            headerParameters["Authorization"] = "Bearer ".concat(tokenString);
                        }
                        _a.label = 2;
                    case 2: return [4 /*yield*/, this.request({
                            path: "/v1/search/inward/plane",
                            method: 'POST',
                            headers: headerParameters,
                            query: queryParameters,
                            body: (0, index_1.InwardSearchRequestToJSON)(requestParameters.inwardSearchRequest),
                        }, initOverrides)];
                    case 3:
                        response = _a.sent();
                        return [2 /*return*/, new runtime.JSONApiResponse(response, function (jsonValue) { return jsonValue.map(index_1.JourneyFromJSON); })];
                }
            });
        });
    };
    /**
     * Search for inward plane journeys.
     */
    DefaultApi.prototype.v1SearchInwardPlanePost = function (requestParameters, initOverrides) {
        return __awaiter(this, void 0, void 0, function () {
            var response;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0: return [4 /*yield*/, this.v1SearchInwardPlanePostRaw(requestParameters, initOverrides)];
                    case 1:
                        response = _a.sent();
                        return [4 /*yield*/, response.value()];
                    case 2: return [2 /*return*/, _a.sent()];
                }
            });
        });
    };
    /**
     * Search for inward train journeys.
     */
    DefaultApi.prototype.v1SearchInwardTrainPostRaw = function (requestParameters, initOverrides) {
        return __awaiter(this, void 0, void 0, function () {
            var queryParameters, headerParameters, token, tokenString, response;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        if (requestParameters.inwardSearchRequest === null || requestParameters.inwardSearchRequest === undefined) {
                            throw new runtime.RequiredError('inwardSearchRequest', 'Required parameter requestParameters.inwardSearchRequest was null or undefined when calling v1SearchInwardTrainPost.');
                        }
                        queryParameters = {};
                        headerParameters = {};
                        headerParameters['Content-Type'] = 'application/json';
                        if (!(this.configuration && this.configuration.accessToken)) return [3 /*break*/, 2];
                        token = this.configuration.accessToken;
                        return [4 /*yield*/, token("bearerAuth", [])];
                    case 1:
                        tokenString = _a.sent();
                        if (tokenString) {
                            headerParameters["Authorization"] = "Bearer ".concat(tokenString);
                        }
                        _a.label = 2;
                    case 2: return [4 /*yield*/, this.request({
                            path: "/v1/search/inward/train",
                            method: 'POST',
                            headers: headerParameters,
                            query: queryParameters,
                            body: (0, index_1.InwardSearchRequestToJSON)(requestParameters.inwardSearchRequest),
                        }, initOverrides)];
                    case 3:
                        response = _a.sent();
                        return [2 /*return*/, new runtime.JSONApiResponse(response, function (jsonValue) { return jsonValue.map(index_1.JourneyFromJSON); })];
                }
            });
        });
    };
    /**
     * Search for inward train journeys.
     */
    DefaultApi.prototype.v1SearchInwardTrainPost = function (requestParameters, initOverrides) {
        return __awaiter(this, void 0, void 0, function () {
            var response;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0: return [4 /*yield*/, this.v1SearchInwardTrainPostRaw(requestParameters, initOverrides)];
                    case 1:
                        response = _a.sent();
                        return [4 /*yield*/, response.value()];
                    case 2: return [2 /*return*/, _a.sent()];
                }
            });
        });
    };
    /**
     */
    DefaultApi.prototype.v1SearchLogPostRaw = function (requestParameters, initOverrides) {
        return __awaiter(this, void 0, void 0, function () {
            var queryParameters, headerParameters, token, tokenString, response;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        if (requestParameters.recordSearchRequest === null || requestParameters.recordSearchRequest === undefined) {
                            throw new runtime.RequiredError('recordSearchRequest', 'Required parameter requestParameters.recordSearchRequest was null or undefined when calling v1SearchLogPost.');
                        }
                        queryParameters = {};
                        headerParameters = {};
                        headerParameters['Content-Type'] = 'application/json';
                        if (!(this.configuration && this.configuration.accessToken)) return [3 /*break*/, 2];
                        token = this.configuration.accessToken;
                        return [4 /*yield*/, token("bearerAuth", [])];
                    case 1:
                        tokenString = _a.sent();
                        if (tokenString) {
                            headerParameters["Authorization"] = "Bearer ".concat(tokenString);
                        }
                        _a.label = 2;
                    case 2: return [4 /*yield*/, this.request({
                            path: "/v1/search-log",
                            method: 'POST',
                            headers: headerParameters,
                            query: queryParameters,
                            body: (0, index_1.RecordSearchRequestToJSON)(requestParameters.recordSearchRequest),
                        }, initOverrides)];
                    case 3:
                        response = _a.sent();
                        return [2 /*return*/, new runtime.VoidApiResponse(response)];
                }
            });
        });
    };
    /**
     */
    DefaultApi.prototype.v1SearchLogPost = function (requestParameters, initOverrides) {
        return __awaiter(this, void 0, void 0, function () {
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0: return [4 /*yield*/, this.v1SearchLogPostRaw(requestParameters, initOverrides)];
                    case 1:
                        _a.sent();
                        return [2 /*return*/];
                }
            });
        });
    };
    /**
     * Search for outward plane journeys.
     */
    DefaultApi.prototype.v1SearchOutwardPlanePostRaw = function (requestParameters, initOverrides) {
        return __awaiter(this, void 0, void 0, function () {
            var queryParameters, headerParameters, token, tokenString, response;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        if (requestParameters.pathfinderSearchRequestFrontend === null || requestParameters.pathfinderSearchRequestFrontend === undefined) {
                            throw new runtime.RequiredError('pathfinderSearchRequestFrontend', 'Required parameter requestParameters.pathfinderSearchRequestFrontend was null or undefined when calling v1SearchOutwardPlanePost.');
                        }
                        queryParameters = {};
                        headerParameters = {};
                        headerParameters['Content-Type'] = 'application/json';
                        if (!(this.configuration && this.configuration.accessToken)) return [3 /*break*/, 2];
                        token = this.configuration.accessToken;
                        return [4 /*yield*/, token("bearerAuth", [])];
                    case 1:
                        tokenString = _a.sent();
                        if (tokenString) {
                            headerParameters["Authorization"] = "Bearer ".concat(tokenString);
                        }
                        _a.label = 2;
                    case 2: return [4 /*yield*/, this.request({
                            path: "/v1/search/outward/plane",
                            method: 'POST',
                            headers: headerParameters,
                            query: queryParameters,
                            body: (0, index_1.PathfinderSearchRequestFrontendToJSON)(requestParameters.pathfinderSearchRequestFrontend),
                        }, initOverrides)];
                    case 3:
                        response = _a.sent();
                        return [2 /*return*/, new runtime.JSONApiResponse(response, function (jsonValue) { return jsonValue.map(index_1.JourneyFromJSON); })];
                }
            });
        });
    };
    /**
     * Search for outward plane journeys.
     */
    DefaultApi.prototype.v1SearchOutwardPlanePost = function (requestParameters, initOverrides) {
        return __awaiter(this, void 0, void 0, function () {
            var response;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0: return [4 /*yield*/, this.v1SearchOutwardPlanePostRaw(requestParameters, initOverrides)];
                    case 1:
                        response = _a.sent();
                        return [4 /*yield*/, response.value()];
                    case 2: return [2 /*return*/, _a.sent()];
                }
            });
        });
    };
    /**
     * Search for outward train journeys.
     */
    DefaultApi.prototype.v1SearchOutwardTrainPostRaw = function (requestParameters, initOverrides) {
        return __awaiter(this, void 0, void 0, function () {
            var queryParameters, headerParameters, token, tokenString, response;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        if (requestParameters.pathfinderSearchRequestFrontend === null || requestParameters.pathfinderSearchRequestFrontend === undefined) {
                            throw new runtime.RequiredError('pathfinderSearchRequestFrontend', 'Required parameter requestParameters.pathfinderSearchRequestFrontend was null or undefined when calling v1SearchOutwardTrainPost.');
                        }
                        queryParameters = {};
                        headerParameters = {};
                        headerParameters['Content-Type'] = 'application/json';
                        if (!(this.configuration && this.configuration.accessToken)) return [3 /*break*/, 2];
                        token = this.configuration.accessToken;
                        return [4 /*yield*/, token("bearerAuth", [])];
                    case 1:
                        tokenString = _a.sent();
                        if (tokenString) {
                            headerParameters["Authorization"] = "Bearer ".concat(tokenString);
                        }
                        _a.label = 2;
                    case 2: return [4 /*yield*/, this.request({
                            path: "/v1/search/outward/train",
                            method: 'POST',
                            headers: headerParameters,
                            query: queryParameters,
                            body: (0, index_1.PathfinderSearchRequestFrontendToJSON)(requestParameters.pathfinderSearchRequestFrontend),
                        }, initOverrides)];
                    case 3:
                        response = _a.sent();
                        return [2 /*return*/, new runtime.JSONApiResponse(response, function (jsonValue) { return jsonValue.map(index_1.JourneyFromJSON); })];
                }
            });
        });
    };
    /**
     * Search for outward train journeys.
     */
    DefaultApi.prototype.v1SearchOutwardTrainPost = function (requestParameters, initOverrides) {
        return __awaiter(this, void 0, void 0, function () {
            var response;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0: return [4 /*yield*/, this.v1SearchOutwardTrainPostRaw(requestParameters, initOverrides)];
                    case 1:
                        response = _a.sent();
                        return [4 /*yield*/, response.value()];
                    case 2: return [2 /*return*/, _a.sent()];
                }
            });
        });
    };
    /**
     * Search for an updated stay with potentially additional details.
     */
    DefaultApi.prototype.v1SearchStaysDetailsPostRaw = function (requestParameters, initOverrides) {
        return __awaiter(this, void 0, void 0, function () {
            var queryParameters, headerParameters, token, tokenString, response;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        if (requestParameters.moreDetailedStayRequest === null || requestParameters.moreDetailedStayRequest === undefined) {
                            throw new runtime.RequiredError('moreDetailedStayRequest', 'Required parameter requestParameters.moreDetailedStayRequest was null or undefined when calling v1SearchStaysDetailsPost.');
                        }
                        queryParameters = {};
                        headerParameters = {};
                        headerParameters['Content-Type'] = 'application/json';
                        if (!(this.configuration && this.configuration.accessToken)) return [3 /*break*/, 2];
                        token = this.configuration.accessToken;
                        return [4 /*yield*/, token("bearerAuth", [])];
                    case 1:
                        tokenString = _a.sent();
                        if (tokenString) {
                            headerParameters["Authorization"] = "Bearer ".concat(tokenString);
                        }
                        _a.label = 2;
                    case 2: return [4 /*yield*/, this.request({
                            path: "/v1/search/stays/details",
                            method: 'POST',
                            headers: headerParameters,
                            query: queryParameters,
                            body: (0, index_1.MoreDetailedStayRequestToJSON)(requestParameters.moreDetailedStayRequest),
                        }, initOverrides)];
                    case 3:
                        response = _a.sent();
                        return [2 /*return*/, new runtime.JSONApiResponse(response, function (jsonValue) { return (0, index_1.StayFromJSON)(jsonValue); })];
                }
            });
        });
    };
    /**
     * Search for an updated stay with potentially additional details.
     */
    DefaultApi.prototype.v1SearchStaysDetailsPost = function (requestParameters, initOverrides) {
        return __awaiter(this, void 0, void 0, function () {
            var response;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0: return [4 /*yield*/, this.v1SearchStaysDetailsPostRaw(requestParameters, initOverrides)];
                    case 1:
                        response = _a.sent();
                        return [4 /*yield*/, response.value()];
                    case 2: return [2 /*return*/, _a.sent()];
                }
            });
        });
    };
    /**
     * Search for stays.
     */
    DefaultApi.prototype.v1SearchStaysPostRaw = function (requestParameters, initOverrides) {
        return __awaiter(this, void 0, void 0, function () {
            var queryParameters, headerParameters, token, tokenString, response;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        if (requestParameters.staySearchRequestUTCFrontend === null || requestParameters.staySearchRequestUTCFrontend === undefined) {
                            throw new runtime.RequiredError('staySearchRequestUTCFrontend', 'Required parameter requestParameters.staySearchRequestUTCFrontend was null or undefined when calling v1SearchStaysPost.');
                        }
                        queryParameters = {};
                        headerParameters = {};
                        headerParameters['Content-Type'] = 'application/json';
                        if (!(this.configuration && this.configuration.accessToken)) return [3 /*break*/, 2];
                        token = this.configuration.accessToken;
                        return [4 /*yield*/, token("bearerAuth", [])];
                    case 1:
                        tokenString = _a.sent();
                        if (tokenString) {
                            headerParameters["Authorization"] = "Bearer ".concat(tokenString);
                        }
                        _a.label = 2;
                    case 2: return [4 /*yield*/, this.request({
                            path: "/v1/search/stays",
                            method: 'POST',
                            headers: headerParameters,
                            query: queryParameters,
                            body: (0, index_1.StaySearchRequestUTCFrontendToJSON)(requestParameters.staySearchRequestUTCFrontend),
                        }, initOverrides)];
                    case 3:
                        response = _a.sent();
                        return [2 /*return*/, new runtime.JSONApiResponse(response, function (jsonValue) { return (0, index_1.StaySearchResponseFromJSON)(jsonValue); })];
                }
            });
        });
    };
    /**
     * Search for stays.
     */
    DefaultApi.prototype.v1SearchStaysPost = function (requestParameters, initOverrides) {
        return __awaiter(this, void 0, void 0, function () {
            var response;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0: return [4 /*yield*/, this.v1SearchStaysPostRaw(requestParameters, initOverrides)];
                    case 1:
                        response = _a.sent();
                        return [4 /*yield*/, response.value()];
                    case 2: return [2 /*return*/, _a.sent()];
                }
            });
        });
    };
    /**
     */
    DefaultApi.prototype.v1SearchVoiStationPostRaw = function (requestParameters, initOverrides) {
        return __awaiter(this, void 0, void 0, function () {
            var queryParameters, headerParameters, token, tokenString, response;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        if (requestParameters.searchVoiStationRequest === null || requestParameters.searchVoiStationRequest === undefined) {
                            throw new runtime.RequiredError('searchVoiStationRequest', 'Required parameter requestParameters.searchVoiStationRequest was null or undefined when calling v1SearchVoiStationPost.');
                        }
                        queryParameters = {};
                        headerParameters = {};
                        headerParameters['Content-Type'] = 'application/json';
                        if (!(this.configuration && this.configuration.accessToken)) return [3 /*break*/, 2];
                        token = this.configuration.accessToken;
                        return [4 /*yield*/, token("bearerAuth", [])];
                    case 1:
                        tokenString = _a.sent();
                        if (tokenString) {
                            headerParameters["Authorization"] = "Bearer ".concat(tokenString);
                        }
                        _a.label = 2;
                    case 2: return [4 /*yield*/, this.request({
                            path: "/v1/search-voi-station",
                            method: 'POST',
                            headers: headerParameters,
                            query: queryParameters,
                            body: (0, index_1.SearchVoiStationRequestToJSON)(requestParameters.searchVoiStationRequest),
                        }, initOverrides)];
                    case 3:
                        response = _a.sent();
                        return [2 /*return*/, new runtime.JSONApiResponse(response, function (jsonValue) { return (0, index_1.SearchVoiStationResponseFromJSON)(jsonValue); })];
                }
            });
        });
    };
    /**
     */
    DefaultApi.prototype.v1SearchVoiStationPost = function (requestParameters, initOverrides) {
        return __awaiter(this, void 0, void 0, function () {
            var response;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0: return [4 /*yield*/, this.v1SearchVoiStationPostRaw(requestParameters, initOverrides)];
                    case 1:
                        response = _a.sent();
                        return [4 /*yield*/, response.value()];
                    case 2: return [2 /*return*/, _a.sent()];
                }
            });
        });
    };
    /**
     * Seed database with stations so they can be looked up with `searchTrainlineFunc`.
     */
    DefaultApi.prototype.v1SeedTrainStationsPostRaw = function (initOverrides) {
        return __awaiter(this, void 0, void 0, function () {
            var queryParameters, headerParameters, token, tokenString, response;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        queryParameters = {};
                        headerParameters = {};
                        if (!(this.configuration && this.configuration.accessToken)) return [3 /*break*/, 2];
                        token = this.configuration.accessToken;
                        return [4 /*yield*/, token("bearerAuth", [])];
                    case 1:
                        tokenString = _a.sent();
                        if (tokenString) {
                            headerParameters["Authorization"] = "Bearer ".concat(tokenString);
                        }
                        _a.label = 2;
                    case 2: return [4 /*yield*/, this.request({
                            path: "/v1/seed-train-stations",
                            method: 'POST',
                            headers: headerParameters,
                            query: queryParameters,
                        }, initOverrides)];
                    case 3:
                        response = _a.sent();
                        return [2 /*return*/, new runtime.VoidApiResponse(response)];
                }
            });
        });
    };
    /**
     * Seed database with stations so they can be looked up with `searchTrainlineFunc`.
     */
    DefaultApi.prototype.v1SeedTrainStationsPost = function (initOverrides) {
        return __awaiter(this, void 0, void 0, function () {
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0: return [4 /*yield*/, this.v1SeedTrainStationsPostRaw(initOverrides)];
                    case 1:
                        _a.sent();
                        return [2 /*return*/];
                }
            });
        });
    };
    /**
     * Seed database with voi station information data.
     */
    DefaultApi.prototype.v1SeedVoiStationsPostRaw = function (initOverrides) {
        return __awaiter(this, void 0, void 0, function () {
            var queryParameters, headerParameters, token, tokenString, response;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        queryParameters = {};
                        headerParameters = {};
                        if (!(this.configuration && this.configuration.accessToken)) return [3 /*break*/, 2];
                        token = this.configuration.accessToken;
                        return [4 /*yield*/, token("bearerAuth", [])];
                    case 1:
                        tokenString = _a.sent();
                        if (tokenString) {
                            headerParameters["Authorization"] = "Bearer ".concat(tokenString);
                        }
                        _a.label = 2;
                    case 2: return [4 /*yield*/, this.request({
                            path: "/v1/seed-voi-stations",
                            method: 'POST',
                            headers: headerParameters,
                            query: queryParameters,
                        }, initOverrides)];
                    case 3:
                        response = _a.sent();
                        return [2 /*return*/, new runtime.VoidApiResponse(response)];
                }
            });
        });
    };
    /**
     * Seed database with voi station information data.
     */
    DefaultApi.prototype.v1SeedVoiStationsPost = function (initOverrides) {
        return __awaiter(this, void 0, void 0, function () {
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0: return [4 /*yield*/, this.v1SeedVoiStationsPostRaw(initOverrides)];
                    case 1:
                        _a.sent();
                        return [2 /*return*/];
                }
            });
        });
    };
    /**
     * Returns timezone identifier for the location provided.
     */
    DefaultApi.prototype.v1TimezonePostRaw = function (requestParameters, initOverrides) {
        return __awaiter(this, void 0, void 0, function () {
            var queryParameters, headerParameters, token, tokenString, response;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        if (requestParameters.location === null || requestParameters.location === undefined) {
                            throw new runtime.RequiredError('location', 'Required parameter requestParameters.location was null or undefined when calling v1TimezonePost.');
                        }
                        queryParameters = {};
                        headerParameters = {};
                        headerParameters['Content-Type'] = 'application/json';
                        if (!(this.configuration && this.configuration.accessToken)) return [3 /*break*/, 2];
                        token = this.configuration.accessToken;
                        return [4 /*yield*/, token("bearerAuth", [])];
                    case 1:
                        tokenString = _a.sent();
                        if (tokenString) {
                            headerParameters["Authorization"] = "Bearer ".concat(tokenString);
                        }
                        _a.label = 2;
                    case 2: return [4 /*yield*/, this.request({
                            path: "/v1/timezone",
                            method: 'POST',
                            headers: headerParameters,
                            query: queryParameters,
                            body: (0, index_1.LocationToJSON)(requestParameters.location),
                        }, initOverrides)];
                    case 3:
                        response = _a.sent();
                        return [2 /*return*/, new runtime.JSONApiResponse(response, function (jsonValue) { return (0, index_1.TimezoneFromJSON)(jsonValue); })];
                }
            });
        });
    };
    /**
     * Returns timezone identifier for the location provided.
     */
    DefaultApi.prototype.v1TimezonePost = function (requestParameters, initOverrides) {
        return __awaiter(this, void 0, void 0, function () {
            var response;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0: return [4 /*yield*/, this.v1TimezonePostRaw(requestParameters, initOverrides)];
                    case 1:
                        response = _a.sent();
                        return [4 /*yield*/, response.value()];
                    case 2: return [2 /*return*/, _a.sent()];
                }
            });
        });
    };
    /**
     * Returns the urlName of the organisation that the user holding the provided access token in the auth header belongs to.
     */
    DefaultApi.prototype.v1UsersMeOrgUrlNameGetRaw = function (initOverrides) {
        return __awaiter(this, void 0, void 0, function () {
            var queryParameters, headerParameters, token, tokenString, response;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        queryParameters = {};
                        headerParameters = {};
                        if (!(this.configuration && this.configuration.accessToken)) return [3 /*break*/, 2];
                        token = this.configuration.accessToken;
                        return [4 /*yield*/, token("bearerAuth", [])];
                    case 1:
                        tokenString = _a.sent();
                        if (tokenString) {
                            headerParameters["Authorization"] = "Bearer ".concat(tokenString);
                        }
                        _a.label = 2;
                    case 2: return [4 /*yield*/, this.request({
                            path: "/v1/users/me/org-url-name",
                            method: 'GET',
                            headers: headerParameters,
                            query: queryParameters,
                        }, initOverrides)];
                    case 3:
                        response = _a.sent();
                        return [2 /*return*/, new runtime.JSONApiResponse(response, function (jsonValue) { return (0, index_1.OrgUrlNameResponseFromJSON)(jsonValue); })];
                }
            });
        });
    };
    /**
     * Returns the urlName of the organisation that the user holding the provided access token in the auth header belongs to.
     */
    DefaultApi.prototype.v1UsersMeOrgUrlNameGet = function (initOverrides) {
        return __awaiter(this, void 0, void 0, function () {
            var response;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0: return [4 /*yield*/, this.v1UsersMeOrgUrlNameGetRaw(initOverrides)];
                    case 1:
                        response = _a.sent();
                        return [4 /*yield*/, response.value()];
                    case 2: return [2 /*return*/, _a.sent()];
                }
            });
        });
    };
    return DefaultApi;
}(runtime.BaseAPI));
exports.DefaultApi = DefaultApi;
